/* 1) The container can grow and won't clip child overflows */
.grading-feedback-container {
  min-height: 100vh;   /* or remove if you want auto-height */
  overflow: visible;   /* changed from auto */
  margin: 1rem;        /* keep or replace with .m-4 if you prefer */
}

/* 2) Ensure the dropdown appears on top */
.assignment-actions-dropdown {
  z-index: 9999; /* High enough to sit above other elements */
}

/* HEADINGS, LABELS, ETC. */
.heading-container .heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  color: #1c1d22;
}
.heading-container .label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #85878d;
}

.user-search-input {
  width: 100% !important;
  max-width: 238px !important;
}
.user-search-input .input-group {
  border: 1px solid #8a8a8a !important;
  border-radius: 4px !important;
}
.user-search-input .form-control {
  height: 40px !important;
  background: #ffffff !important;
}
.user-search-input .input-group-text {
  background: #ffffff !important;
}

.assignment-btn {
  height: 40px !important;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #0343fa !important;
  background: #ffffff !important;
}
.assignment-btn span {
  color: #0343fa !important;
  font-size: 14px !important;
}

.primary-btn {
  background: #0343fa !important;
}
.primary-btn span {
  color: #ffffff !important;
}

.page-dropdown {
  font-size: 12.77px;
  font-weight: 500;
  line-height: 17.88px;
  color: #1c1d22;
}
.page-dropdown select {
  height: 39.03px;
  border-radius: 4px;
  border: 1px solid #1c1d22;
  padding: 8.52px 12.77px;
}

.status-filter {
  position: relative;
}
.status-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000; 
  width: 150px; 
}
.status-option {
  padding: 8px 12px;
  cursor: pointer;
}
.status-option:hover {
  background-color: #f0f0f0;
}

.tab-btn {
  background-color: transparent !important;
  color: #007bff !important;
  border: none !important;
  padding: 8px 16px !important;
  cursor: pointer !important;
  transition: color 0.3s, border-bottom 0.3s !important;
  position: relative !important;
}
.tab-btn span {
  color: #6a7179 !important;
}
.tab-btn::after {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: -2px !important;
  height: 2px !important;
  background-color: transparent !important;
  transition: background-color 0.3s !important;
}
.tab-btn:hover {
  color: #0056b3;
}
.active-btn span {
  color: #007bff !important;
}
.active-btn::after {
  background-color: #007bff !important;
}

hr {
  margin: 0;
  margin-bottom: 1rem;
}

/* Rating modal classes (unchanged) */
.rating-modal .signature-text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 13.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.437px;
  letter-spacing: 0.17px;
}
.rating-modal .success-popup-text {
  margin-bottom: 0.5rem !important;
}

/* A base style for all labels */
.label-base {
  display: inline-flex;      /* inline so it only wraps content */
  align-items: center;       /* center the circle & text vertically */
  gap: 4px;                  /* space between circle <span> and text <label> */
  border-radius: 38px;
  width: fit-content;
  padding: 6px 10px;
}

/* The small colored circle dot */
.label-base span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

/* The text portion */
.label-base label {
  margin: 0;
  background: transparent; /* let the parent handle background color */
}

/* 1) Awaiting Learner Submission */
.label-awaiting {
  background: #f0f4ff; /* pale bluish background */
  color: #0056b3;      /* darker blue text */
}
.label-awaiting span {
  background: #0056b3; /* the little circle's color */
}
.label-awaiting label {
  color: #0056b3;
}

/* 2) Under Review */
.label-under-review {
  background: #ecfdec; /* pale green */
  color: #228b22;      /* forest green */
}
.label-under-review span {
  background: #228b22;
}
.label-under-review label {
  color: #228b22;
}

/* If status == Marked, we skip the status label
   and use feedback.result to pick one of the following: */

/* 3) Low Pass */
.label-low-pass {
  background: #ebf5ff; 
  color: #007bff;
}
.label-low-pass span {
  background: #007bff;
}
.label-low-pass label {
  color: #007bff;
}

/* 4) Fail */
.label-fail {
  background: #ffecec;
  color: #ff3333;
}
.label-fail span {
  background: #ff3333;
}
.label-fail label {
  color: #ff3333;
}

/* 5) High Pass */
.label-high-pass {
  background: #ebf5ff;
  color: #007bff;
}
.label-high-pass span {
  background: #007bff;
}
.label-high-pass label {
  color: #007bff;
}

/* 6) Pass */
.label-pass {
  background: #ebf5ff;
  color: #007bff;
}
.label-pass span {
  background: #007bff;
}
.label-pass label {
  color: #007bff;
}

/* 7) Resubmission 1 and 2 
   (or just one "label-resubmission" if you want the same style) */
.label-resubmission1 {
  background: #fff4e0;
  color: #996800;
}
.label-resubmission1 span {
  background: #996800;
}
.label-resubmission1 label {
  color: #996800;
}

.label-resubmission2 {
  background: #fff4e0;
  color: #996800;
}
.label-resubmission2 span {
  background: #996800;
}
.label-resubmission2 label {
  color: #996800;
}
