.tutorials-page-container {
  padding: 24px;
  min-height: 100vh; /* ensure full page height */
  background-color: #f8f9fa; /* light gray background */
  box-sizing: border-box;
}

/* Header section */
.tutorials-header {
  margin-bottom: 24px;
}
.tutorials-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 8px;
}
.tutorials-subtitle {
  font-size: 1rem;
  color: #555;
}

/* Main content: two columns (desktop). For small screens, stack them. */
.tutorials-content {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
@media (max-width: 768px) {
  .tutorials-content {
    flex-direction: column;
  }
}

/* Video list (left) */
.tutorials-video-list {
  flex: 1;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.section-heading {
  font-size: 1.25rem;
  margin-bottom: 16px;
}

.video-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.video-list li {
  margin-bottom: 8px;
}

/* Each "video button" you can click to load the iframe */
.video-button {
  width: 100%;
  text-align: left;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.2s ease;
}
.video-button:hover {
  background-color: #e1e1e1;
}
.video-button.active {
  background-color: #007bff;
  color: #fff;
}

/* Video player (right) */
.tutorials-video-player {
  flex: 2;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-container {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio for youtube videos */
  height: 0;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.no-video-selected {
  text-align: center;
  color: #888;
}

/* Documentation section */
.tutorials-doc-section {
  margin-top: 24px;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}
.doc-link {
  display: inline-block;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s ease;
}
.doc-link:hover {
  background-color: #0056b3;
}