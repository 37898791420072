/*****************************************
 * 1. BASE MODAL STYLES
 *****************************************/
 .modal {
  transition: all 0.3s ease-in-out; 
  overflow: hidden;                
  scrollbar-width: none;
}

.modal-dialog {
  max-width: 800px;
  width: 100%;
  margin: auto;
  top: 10%;
  transform: translateY(-50%);
}

.modal-content {
  border-radius: 8px;
  overflow-y: auto;
}

/*****************************************
 * 2. MAXIMIZED MODAL
 *****************************************/
.maximized .modal-dialog {
  top: 1%;
  margin: 0;
  width: 98%;
  height: 100%;
  max-width: none;
}

.maximized .modal-content {
  border-radius: 8px;
}

.maximized .modal-body {
  max-height: calc(95vh - 120px);
  overflow-y: auto;
  scrollbar-width: none;
}

/*****************************************
 * 3. MODAL HEADER & BODY
 *****************************************/
.modal-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  padding: 25px;
  border-bottom: 1px solid #dee2e6;
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.modal-body {
  max-height: calc(80vh - 120px);
  overflow-y: auto;
  scrollbar-width: none;
}

/*****************************************
 * 4. FORMS & INPUTS
 *****************************************/
.required-star {
  color: red;
}

.custom-input,
.custom-textarea,
.custom-fileupload {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f3f5fa;
}

input {
  height: 45px !important;
  background: none !important;
}

/*****************************************
 * 5. FILE UPLOAD
 *****************************************/
.custom-fileupload {
  /* Original styling for your FileUpload container */
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .browse-label {
    margin: 0 20px;
  }
}

/* 
 * Additional classes for a dashed drop zone 
 * to keep "Drop files" text & "Browse" button
 * neatly inside a box.
 */
.upload-assignment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;  /* Adjust as needed */
  margin: 0 auto;    /* Centers horizontally */
}

.drop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

.browse-button {
  background-color: #007bff;
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.browse-button:hover {
  background-color: #0056b3;
}

/*****************************************
 * 6. BUTTONS
 *****************************************/
.proceed-btn {
  white-space: nowrap;
}

.assigned-btn {
  white-space: nowrap;
  color: #bdbdbd !important;
  background: #eeeeee !important;
  border: 1px solid #bdbdbd !important;
}

.assigned-btn:hover {
  background: #cdd9fe !important;
  color: #0343fa !important;
  border: 1px solid #0343fa !important;
}

.download-button {
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #007bff;
  color: #007bff;
  width: 100%;
}

.download-button:hover {
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
}

/*****************************************
 * 7. SUCCESS MODAL
 *****************************************/
.success-modal .modal-content {
  border-radius: 8px;
  width: 60%;
  margin: auto;
  align-items: center;
}

.success-modal .cstm-img {
  margin: 60px;
  margin-bottom: 35px;
}

.cstm-lh {
  line-height: 45px;
}

.cstm-lh-upload {
  line-height: 35px;
}

/*****************************************
 * 8. RESPONSIVE QUERIES
 *****************************************/
@media (max-width: 768px) {
  .custom-fileupload {
    flex-direction: column;
    height: auto;

    .browse-label {
      display: none !important;
    }

    .browse-icon {
      display: inline-block;
      cursor: pointer;
      font-size: x-large;
      border: 2px solid #007bff;
      border-radius: 0.5rem;
      color: #007bff;
      padding: 5px;
    }
  }

  .modal-footer {
    display: block;
    padding: 15px 0;
  }

  .success-modal .modal-content {
    width: 80%;
  }

  .cstm-img {
    margin: 30px;
    margin-bottom: 20px;
  }

  .flotting-btn {
    position: absolute;
    top: 20px;
    right: 10px;
    width: min-content;
  }
}

@media (min-width: 769px) {
  .browse-icon {
    display: none;
  }

  .flotting-btn {
    top: 50px;
    right: 20px;
  }
}

/*****************************************
 * 9. MISC
 *****************************************/
.error-span {
  color: red;
  padding-left: 40px;
}

.flotting-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
  width: min-content;
}

.txt-lbl {
  font-weight: 900 !important;
}
